import React, { useState } from 'react'
import Layout from '../Component/Layout/Layout';
import { Button, Col, Container, Modal, Row, Toast } from 'react-bootstrap';
import Select, { components } from "react-select"
import sedan from "../images/sedan_new.png";
import suv from "../images/suv_new.png";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../Component/Loader';
export const URL = "https://rstravel.in/send-email";
const BookingPage = () => {
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [km, setKm] = useState("0");
    const [show, setShow] = useState(false);
    const [carData, setCarData] = useState([]);
    const [first, setFirst] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [pickUpAddress, setPickupAddress] = useState("");
    const [dropOffAddress, setDropOffAddress] = useState("");
    const [spanDisplay, setSpanDisplay] = useState("none");
    const [paymentType, setPaymentType] = useState("");
    const [price, setPrice] = useState("");
    const [departure, setDeparture] = useState("");
    const [time, setTime] = useState("");
    const [spanDisplay2, setSpanDisplay2] = useState("none");
    const [selectedCar, setSelectedCar] = useState("");
    const [selectedCarType, setSelectedCarType] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [loader, setLoader] = useState(false);
    const locationsData = [
        { id: 1, name: "Agra" },
        { id: 2, name: "Delhi" },
        { id: 5, name: "Jaipur" },
        { id: 6, name: "Lucknow" },
        { id: 7, name: "Kanpur" },
        { id: 8, name: "Haridwar, Rishikesh" },
        { id: 9, name: "Chandigarh" },
        { id: 10, name: "Mathura" },
        { id: 11, name: "Noida" },
        { id: 12, name: "Gurugram" },
        { id: 13, name: "Bharatpur" },
    ]

    const distanceData = [
        { id: 1, From: "Agra", To: "Delhi", km: "221" },
        { id: 2, From: "Delhi", To: "Agra", km: "221" },
        { id: 3, From: "Delhi", To: "Haridwar, Rishikesh", km: "228" },
        { id: 4, From: "Haridwar, Rishikesh", To: "Delhi", km: "228" },
        { id: 5, From: "Agra", To: "Lucknow", km: "336" },
        { id: 6, From: "Lucknow", To: "Agra", km: "336" },
        { id: 7, From: "Lucknow", To: "Delhi", km: "535" },
        { id: 8, From: "Delhi", To: "Lucknow", km: "535" },
        { id: 9, From: "Agra", To: "Kanpur", km: "301" },
        { id: 10, From: "Kanpur", To: "Agra", km: "301" },
        { id: 11, From: "Kanpur", To: "Delhi", km: "502" },
        { id: 12, From: "Delhi", To: "Kanpur", km: "501" },
        { id: 13, From: "Delhi", To: "Chandigarh", km: "248" },
        { id: 14, From: "Chandigarh", To: "Delhi", km: "248" },
        { id: 15, From: "Agra", To: "Jaipur", km: "248" },
        { id: 16, From: "Jaipur", To: "Agra", km: "248" },
        { id: 17, From: "Jaipur", To: "Delhi", km: "314" },
        { id: 18, From: "Delhi", To: "Jaipur", km: "314" },
        { id: 19, From: "Noida", To: "Haridwar, Rishikesh", km: "217" },
        { id: 20, From: "Haridwar, Rishikesh", To: "Noida", km: "217" },
        { id: 21, From: "Gurugram", To: "Haridwar, Rishikesh", km: "252" },
        { id: 23, From: "Haridwar, Rishikesh", To: "Gurugram", km: "252" },
        { id: 24, From: "Haridwar, Rishikesh", To: "Gurugram", km: "252" },
        { id: 25, From: "Agra", To: "Mathura", km: "60" },
        { id: 26, From: "Mathura", To: "Agra", km: "60" },
        { id: 27, From: "Mathura", To: "Delhi", km: "185" },
        { id: 28, From: "Delhi", To: "Mathura", km: "185" },
        { id: 29, From: "Noida", To: "Agra", km: "200" },
        { id: 30, From: "Agra", To: "Noida", km: "200" },
        { id: 31, From: "Delhi", To: "Bharatpur", km: "225" },
        { id: 32, From: "Bharatpur", To: "Delhi", km: "225" },
        { id: 33, From: "Agra", To: "Bharatpur", km: "60" },
        { id: 34, From: "Bharatpur", To: "Agra", km: "60" },
        { id: 35, From: "Agra", To: "Gurugram", km: "200" },
        { id: 36, From: "Gurugram", To: "Agra", km: "200" },
    ]


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: '5rem',
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
            paddingTop: '1.5rem', // Added padding to ensure the label and the selected option do not overlap
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '100%', // Ensures that the value container also takes the full height
            display: 'flex',
            alignItems: 'center', // Centers the text vertically
            paddingTop: '1rem', // Added padding to push the selected option below the label
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'gray', // Optional: Customize placeholder text color
        }),
        singleValue: (provided) => ({
            ...provided,
            marginTop: '0.5rem', // Adjust the margin to align selected value properly
        }),
    };

    const CustomControl = ({ children, ...props }) => (
        <components.Control {...props}>
            <label
                htmlFor="From"
                style={{
                    position: 'absolute',
                    left: '10px',
                    top: '0.5rem', // Position the label above the selected option
                    pointerEvents: 'none',
                    color: '#999',
                    fontSize: '1rem',
                }}
            >
                {props.selectProps.label}
            </label>
            {children}
        </components.Control>
    );

    const setDataOfCars = (from, to) => {
        if (from === "Agra" && to === "Delhi") {
            console.log(from, " ", to)
            setCarData(
                [
                    { id: 1, carName: "Eon, Wagnor", similar: "or similar", carSpace: "Spacious", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "10", fuelType: "CNG" },
                    { id: 2, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "12", fuelType: "CNG" },
                    { id: 3, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 4, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "Diesel" },
                    { id: 5, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "3500", extraCharges: "14", fuelType: "CNG" },
                    { id: 6, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Delhi" && to === "Agra") {
            setCarData(
                [
                    { id: 1, carName: "Eon, Wagnor", similar: "or similar", carSpace: "Spacious", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "10", fuelType: "CNG" },
                    { id: 2, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "12", fuelType: "CNG" },
                    { id: 3, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 4, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "Diesel" },
                    { id: 5, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "3500", extraCharges: "14", fuelType: "CNG" },
                    { id: 6, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Delhi" && to === "Haridwar, Rishikesh") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Haridwar, Rishikesh" && to === "Delhi") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Haridwar, Rishikesh" && to === "Noida") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Noida" && to === "Haridwar, Rishikesh") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Haridwar, Rishikesh" && to === "Gurugram") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Gurugram" && to === "Haridwar, Rishikesh") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }

        else if (from === "Agra" && to === "Lucknow") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4800", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "5000", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "5700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "5500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Lucknow" && to === "Agra") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "4800", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "5000", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "5700", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "5500", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Delhi" && to === "Lucknow") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "7000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "7200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "17000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Lucknow" && to === "Delhi") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "7000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "7200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "17000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Agra" && to === "Kanpur") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "5000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "5200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Kanpur" && to === "Agra") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "5000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "5200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "9000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Kanpur" && to === "Delhi") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "6000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "6200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "17000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Delhi" && to === "Kanpur") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "6000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "6200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "8000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "17000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Delhi" && to === "Chandigarh") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Chandigarh" && to === "Delhi") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3200", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Agra" && to === "Jaipur") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3500", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3700", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Jaipur" && to === "Agra") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3500", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3700", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Jaipur" && to === "Delhi") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3500", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3700", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Delhi" && to === "Jaipur") {
            setCarData(
                [
                    { id: 1, carName: "Dzire, Aura", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3500", extraCharges: "12", fuelType: "CNG" },
                    { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3700", extraCharges: "12", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4200", extraCharges: "14", fuelType: "Diesel" },
                    { id: 2, carName: "Ertiga, Triber", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "14", fuelType: "CNG" },
                    { id: 2, carName: "Innova Crysta", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "17", fuelType: "Diesel" },
                ]
            )
        }
        else if (from === "Mathura" && to === "Agra") {
            setCarData([
                { id: 1, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "CNG" },
                { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "Diesel" },
                { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "2000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Bharatpur" && to === "Agra") {
            setCarData([
                { id: 1, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "CNG" },
                { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "Diesel" },
                { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "2000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Agra" && to === "Bharatpur") {
            setCarData([
                { id: 1, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "CNG" },
                { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "Diesel" },
                { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "2000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Mathura" && to === "Delhi") {
            setCarData([
                { id: 1, carName: "Eon, Wagonr", similar: "or similar", carSpace: "Compact", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 3, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "3500", extraCharges: "Included", fuelType: "Diesel" },
                { id: 4, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Noida" && to === "Agra") {
            setCarData([
                { id: 1, carName: "Eon, Wagonr", similar: "or similar", carSpace: "Compact", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 3, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 4, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Bharatpur" && to === "Delhi") {
            setCarData([
                { id: 1, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 3, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "5000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Agra" && to === "Mathura") {
            setCarData([
                { id: 1, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "CNG" },
                { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "1500", extraCharges: "Included", fuelType: "Diesel" },
                { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "2000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Delhi" && to === "Mathura") {
            setCarData([
                { id: 1, carName: "Eon, Wagonr", similar: "or similar", carSpace: "Compact", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 3, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "3500", extraCharges: "Included", fuelType: "Diesel" },
                { id: 4, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Agra" && to === "Noida") {
            setCarData([
                { id: 1, carName: "Eon, Wagonr", similar: "or similar", carSpace: "Compact", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 3, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 4, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Delhi" && to === "Bharatpur") {
            setCarData([
                { id: 1, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 1, carName: "Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 2, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 3, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "5000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Agra" && to === "Gurugram") {
            setCarData([
                { id: 1, carName: "Eon, Wagonr", similar: "or similar", carSpace: "Compact", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 3, carName: "Dzire", similar: "", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3200", extraCharges: "Included", fuelType: "Diesel" },
                { id: 4, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "3500", extraCharges: "Included", fuelType: "CNG" },
                { id: 5, carName: "Ertiga", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 6, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else if (from === "Gurugram" && to === "Agra") {
            setCarData([
                { id: 1, carName: "Eon, Wagonr", similar: "or similar", carSpace: "Compact", carType: "Hatchback", ac: "AC", seats: "4 Seats", charge: "2500", extraCharges: "Included", fuelType: "CNG" },
                { id: 2, carName: "Aura, Dzire", similar: "or similar", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3000", extraCharges: "Included", fuelType: "CNG" },
                { id: 3, carName: "Dzire", similar: "", carSpace: "Spacious", carType: "Sedan", ac: "AC", seats: "4 Seats", charge: "3200", extraCharges: "Included", fuelType: "Diesel" },
                { id: 4, carName: "Ertiga", similar: "or similar", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "3500", extraCharges: "Included", fuelType: "CNG" },
                { id: 5, carName: "Ertiga", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "4000", extraCharges: "Included", fuelType: "Diesel" },
                { id: 6, carName: "Innova", similar: "", carSpace: "Large Car", carType: "SUV", ac: "AC", seats: "6 Seats", charge: "7000", extraCharges: "Included", fuelType: "Diesel" }
            ])
        }
        else {
            setCarData([]);
        }
    }

    const handleSearch = () => {


        if (!from || !to || !departure || !time) {
            setSpanDisplay2("block")
        }
        else if (from.name === to.name) {
            toast.error("From and Two shoud not be same")

            setShow(false);
        }
        else {
            setFirst(false);

            setDataOfCars(from.name, to.name);
            const disstance = distanceData.filter(entry => entry.From === from?.name && entry.To === to?.name);
            if (disstance.length != 0) {

                console.log(disstance)
                setKm(disstance[0]?.km)
                setShow(true);
            }
            else {
                setShow(false);
            }
        }

    }

    const handleBooking = async () => {
        try {
            if (!name || !email || !number || !pickUpAddress || !dropOffAddress || !price) {
                setSpanDisplay("block");
            }
            else {
                setLoader(true);
                let message = `
                                <p><strong>Name:</strong> ${name}</p>
                                <p><strong>Email:</strong> ${email}</p>
                                <p><strong>Phone Number:</strong> ${number}</p>
                                <p><strong>Date :</strong> ${departure}</p>
                                <p><strong>Time :</strong> ${time}</p>
                                <p><strong>From :</strong> ${from.name}</p>
                                <p><strong>To :</strong> ${to.name}</p>
                                <p><strong>Pick-up Address:</strong> ${pickUpAddress}</p>
                                <p><strong>Drop-off Address:</strong> ${dropOffAddress}</p>
                                <p><strong>Cab Type:</strong>${selectedCarType} (${selectedCar} - ${fuelType}) </p>
                                <p><strong>Trip Type:</strong> One Way </p>
                                <p><strong>Total Payment:</strong> ${price} (${km} Km Include)</p>
                                `
                const { data } = await axios.post(URL, {
                    name,
                    email,
                    message
                });
                if (data?.success) {
                    toast.success("Booking Confirmed");
                    console.log('Email sent successfully:', data);
                    setModalShow(false);
                    setLoader(false);
                }
                else {
                    setLoader(false);
                    toast.error("Something went wrong please try again later.");
                }
            }

        } catch (error) {
            setLoader(false);
            console.error('Error sending email:', error);
            toast.error("Something went wrong")
        }
    }

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);

    };

    const handleBookNow = (d) => {
        setModalShow(true);
        setPrice(Math.round(d.charge));
        setSelectedCar(d.carName);
        setSelectedCarType(d.carType);
        setFuelType(d.fuelType)
    }
    return (
        <>
            <Layout>
                {loader ? (
                    <Loader />
                ) : ("")}
                <Container className="main-content my-5">
                    <div className="" style={{ background: "white", borderRadius: "1rem", padding: "1rem", color: "black", position: "sticky", }}>
                        <Row>
                            <Col xs={12} sm={6} md={4} lg={4} >

                                <div class="form-group mt-4">

                                    <Select
                                        value={from}
                                        onChange={(e) => setFrom(e)}
                                        options={locationsData}
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.name?.toString()}
                                        classNamePrefix="select2-selection"
                                        styles={customStyles}
                                        components={{ Control: CustomControl }}
                                        label="From"
                                        placeholder=""

                                    />
                                    {!from && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}

                                </div>


                            </Col>
                            <Col xs={12} sm={6} md={4} lg={4} >

                                <div class="form-group mt-4">

                                    <Select
                                        value={to}
                                        onChange={(e) => setTo(e)}
                                        options={locationsData}
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.name?.toString()}
                                        classNamePrefix="select2-selection"
                                        styles={customStyles}
                                        components={{ Control: CustomControl }}
                                        label="To"
                                        placeholder=""

                                    />
                                    {!to && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}

                                </div>


                            </Col>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <div className="form-group mt-4" style={{ position: "relative" }}>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="From1" // Use unique IDs for each input and label
                                        value={departure}
                                        onChange={(e) => setDeparture(e.target.value)}
                                        style={{
                                            height: "5rem",
                                            width: "100%", // Ensure input takes full width
                                            paddingLeft: "10px", // Adjust padding as needed
                                        }}

                                    />
                                    {!departure && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}
                                    <label
                                        htmlFor="From1"
                                        style={{
                                            position: "absolute",
                                            top: "-1rem", // Adjust to position label at desired height
                                            left: "10px",
                                            backgroundColor: "white", // Ensure label background is same as input
                                            padding: "0 5px",
                                            color: "#aaa",
                                        }}
                                    >
                                        Departure
                                    </label>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <div className="form-group mt-4" style={{ position: "relative" }}>
                                    <input
                                        className="form-control"
                                        type="time"
                                        id="From2" // Use unique IDs for each input and label
                                        value={time}
                                        onChange={(e) => setTime(e.target.value)}
                                        style={{
                                            height: "5rem",
                                            width: "100%", // Ensure input takes full width
                                            paddingLeft: "10px", // Adjust padding as needed
                                        }}
                                    />
                                    {!time && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}
                                    <label
                                        htmlFor="From2"
                                        style={{
                                            position: "absolute",
                                            top: "-1rem", // Adjust to position label at desired height
                                            left: "10px",
                                            backgroundColor: "white", // Ensure label background is same as input
                                            padding: "0 5px",
                                            color: "#aaa",
                                        }}
                                    >
                                        Pick Up Time
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mt-3 m-auto d-flex justify-content-end">
                                    <Button style={{
                                        background: "linear-gradient(93deg, #53b2fe, #065af3)",
                                        backgroundColor: "var(--color-btn-primary-bg)",
                                        color: "white",
                                        fontWeight: "bolder"
                                    }}
                                        onClick={handleSearch}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {show ?
                        <>
                            <div className="mt-5">
                                {carData.map((d, i) => (
                                    <>



                                        <div className="mt-2 ">
                                            <div className="cab-card">
                                                <Row>
                                                    <Col xs={2} sm={2} md={2} lg={2}>
                                                        <div className="m-sm-3">
                                                            <img src={sedan} alt="" width="100" />
                                                        </div>
                                                    </Col>
                                                    <Col xs={7} sm={7} md={7} lg={8}>
                                                        <div className="" style={{ color: "black" }}>
                                                            <div className="d-flex head">
                                                                <h3>{d.carName}</h3>
                                                                <span className='mt-2 ms-2' style={{ color: "gray" }}>{d.similar}</span>
                                                            </div>

                                                            <div className="">
                                                                <ul className='d-flex f-wrap' style={{ flexWrap: "wrap" }}>
                                                                    <li className='me-4'>{d.carType}</li>
                                                                    <li className='me-4'>{d.ac}</li>
                                                                    <li className='me-4'>{d.seats}</li>
                                                                    <li className='me-4'>{km} Kms included</li>
                                                                </ul>
                                                            </div>
                                                            <div className="">
                                                                <h4>
                                                                    {d.carSpace}
                                                                </h4>
                                                            </div>
                                                            <div className="w-md-75 w-sm-100 w-xs-100">
                                                                <div className="d-flex justify-content-between align-items-start">
                                                                    <h6 className=''><AddLocationAltIcon className="icon" fontSize="small" color="primary" /> Extra km fare </h6>
                                                                    <h6>₹{d.extraCharges}/km after {km} kms</h6>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-start">
                                                                    <h6 className=''><LocalGasStationIcon className="icon" fontSize="small" color="primary" /> Fuel type </h6>
                                                                    <h6>{d.fuelType}</h6>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-start">
                                                                    <h6 className=''><CancelIcon className="icon" fontSize="small" color="primary" />Cancellation </h6>
                                                                    <h6><span style={{ color: "green" }}>Free</span> till 1 hour of departure</h6>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={2}>
                                                        <div className="mt-3" style={{ color: "black" }}>
                                                            <h3 className='price' >₹ {Math.round(d.charge)} </h3>
                                                            <p>(All taxes and charges included)</p>
                                                            <div className="mt-3">
                                                                <Button className="button" style={{
                                                                    background: "linear-gradient(93deg, #53b2fe, #065af3)",
                                                                    backgroundColor: "var(--color-btn-primary-bg)",
                                                                    color: "white",
                                                                    fontWeight: "bolder",
                                                                    borderRadius: "1.5rem"
                                                                }}
                                                                    onClick={() => handleBookNow(d)}
                                                                >
                                                                    Book Now
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                    </>
                                ))}
                            </div>
                        </>
                        :
                        <>
                            {!first &&
                                <div className="m-auto mt-5 text-center">
                                    <h3>Not Available</h3>
                                </div>}
                        </>}
                </Container>
                <Modal
                    show={modalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Book Your Cab
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Name
                            </label>
                            <div className="col-md-10">
                                <input type="text"
                                    className='form-control'
                                    placeholder="Enter Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                                {!name && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Mobile Number
                            </label>
                            <div className="col-md-10">
                                <input type="number"
                                    className='form-control'
                                    placeholder="Mobile Number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)} />
                                {!number && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Email
                            </label>
                            <div className="col-md-10">
                                <input type="text"
                                    className='form-control'
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                {!email && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Pick Up Address
                            </label>
                            <div className="col-md-10">
                                <input type="text"
                                    className='form-control'
                                    placeholder="Enter Pick Up Address"
                                    value={pickUpAddress}
                                    onChange={(e) => setPickupAddress(e.target.value)} />
                                {!pickUpAddress && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Drop Off Address
                            </label>
                            <div className="col-md-10">
                                <input type="text"
                                    className='form-control'
                                    placeholder="Enter Drop Off Address"
                                    value={dropOffAddress}
                                    onChange={(e) => setDropOffAddress(e.target.value)} />
                                {!dropOffAddress && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>

                        {/* <Row>



                            <div className="form-check form-check-inline ms-5">
                                <div className="me-5">
                                    <input
                                        className='form-check-input mt-3 me-3'
                                        type="radio"
                                        name="paytmentType"
                                        id="type2"
                                        value="part"
                                        checked={paymentType === 'part'}
                                        onChange={handlePaymentTypeChange}
                                    />
                                </div>
                                <label className="form-check-label" htmlFor="exampleRadios2">
                                    Make part Payment now <span className='ms-5' style={{ fontSize: "1.5rem" }}>₹ 100</span>
                                </label>
                            </div>
                        </Row>

                        <Row>
                            <div className="form-check form-check-inline ms-5">
                                <div className="me-5">
                                    <input
                                        className='form-check-input mt-3 me-3'
                                        type="radio"
                                        name="type"
                                        id="type1"
                                        value="full"
                                        checked={paymentType === 'full'}
                                        onChange={handlePaymentTypeChange}
                                    />
                                </div>
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    Make full Payment now <span className='ms-5' style={{ fontSize: "1.5rem" }}>₹ {price}</span>
                                </label>
                            </div>
                        </Row> */}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" color={loader ? "secondary" : "primary"} onClick={() => setModalShow(false)} className="waves-effect waves-light" disabled={loader}>Close</Button>{" "}
                        <Button type="button" color={loader ? "secondary" : "success"} onClick={handleBooking} className="waves-effect waves-light" disabled={loader}>Book Now</Button>{" "}

                    </Modal.Footer>
                </Modal>
            </Layout>
        </>
    )
}

export default BookingPage;
