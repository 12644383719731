import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 bg-dark text-white">
            <Container>
                <Row>
                    <Col xs={12} className="text-center">
                        <h1><i className="bi bi-telephone-fill"></i> +91 94102 02321</h1>
                        © {new Date().getFullYear()} by RS TRAVEL.IN.
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
