import Layout from "../Component/Layout/Layout";



// function Contact() {
//     return (
//         <>
//             <div className="App">
//                 <Layout>
//                     <div className="main-content">
//                         <div className="d-flex flex-column-reverse flex-md-row m-auto mt-5" style={{ width: "90%" }}>
//                             <div className="d-flex justify-content-center align-items-center text-center" style={{ flex: "1", minHeight: "30rem", height: "40rem", border: "2px solid black", backgroundColor: "#2e4b57", padding: "3rem" }}>
//                                 <div>
//                                     <h1>WE WANT TO HEAR</h1>
//                                     <h1>FROM YOU!</h1>
//                                     <p>Agra, Uttar Pradesh, India</p>
//                                     <p>+91 94102 02321</p>
//                                 </div>
//                             </div>
//                             <div className="mt-3 mt-md-0" style={{ flex: "1", border: "2px solid black", backgroundColor: "white", minHeight: "30rem" }}>
//                                 <iframe
//                                     title="Google Map"
//                                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d143198.40004617287!2d77.93159373348064!3d27.100540250551596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39740d857c2f41d9%3A0x784aef38a9523b42!2sAgra%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1717828142931!5m2!1sen!2sin"
//                                     width="100%"
//                                     height="100%"
//                                     style={{ border: 0 }}
//                                     allowFullScreen=""
//                                     loading="lazy"
//                                     referrerPolicy="no-referrer-when-downgrade"
//                                 ></iframe>
//                             </div>
//                         </div>
//                     </div>
//                 </Layout>
//             </div>
//         </>
//     )
// };


function Contact() {
    return (
        <>
            <div className="App">
                <Layout>
                    <div className="main-content">
                        <div className="d-flex flex-column m-auto mt-5" style={{ width: "90%" }}>
                            {/* Content Section */}
                            <div className="d-flex justify-content-center align-items-center text-center mb-4" style={{ minHeight: "30rem", height: "auto", border: "2px solid black", backgroundColor: "#2e4b57", padding: "3rem" }}>
                                <div>
                                    <h1>WE WANT TO HEAR</h1>
                                    <h1>FROM YOU!</h1>
                                    <p>Agra, Uttar Pradesh, India</p>
                                    <p>+91 94102 02321</p>
                                </div>
                            </div>

                            {/* Map Section */}
                            <div className="mb-4" style={{ border: "2px solid black", backgroundColor: "white", minHeight: "30rem" }}>
                                <iframe
                                    title="Google Map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d143198.40004617287!2d77.93159373348064!3d27.100540250551596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39740d857c2f41d9%3A0x784aef38a9523b42!2sAgra%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1717828142931!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0, minHeight: "30rem" }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        </>
    )
}


export default Contact;