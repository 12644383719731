import Layout from "../Component/Layout/Layout";
import logo5 from "../logo5.webp"



function About() {
    return (
        <>
            <div className="App">
                <Layout>
                    <div className="main-content">
                        <h1 className="text-center" style={{ textDecoration: "underline", color: "#F8BD26", fontSize: "4rem", marginTop: "2rem" }}>WHO WE ARE</h1>
                        <div className="d-flex flex-column flex-md-row m-auto mt-5" style={{ maxWidth: "1200px", width: "90%", margin: "0 auto" }}>
                            <div className="mb-4 mb-md-0" style={{ flex: "1", minHeight: "10rem", height: "auto", padding: "2rem", display: "flex", justifyContent: "center", alignItems: "start" }}>
                                <img src={logo5} alt="RS Travels Logo" style={{ maxWidth: "100%", height: "auto" }} />
                            </div>
                            <div className="ml-md-4 ps-md-5" style={{ flex: "1", minHeight: "30rem", height: "auto", paddingLeft: "0", padding: "2rem", fontSize: "1.4rem" }}>
                                <p>RS Travels is a tour and travel company based in Agra, Uttar Pradesh, India. It was founded in 2007 by Ram Kumar Kushwaah and offers various types of trips including honeymoon trips, vacation trips, and business trips. The company is known for providing customized travel packages to suit its customers' preferences and budgets.</p>

                                <p>RS Travels aims to offer a seamless and hassle-free travel experience to its customers by providing end-to-end services, including transportation, accommodation, sightseeing, and other travel-related services. The company has a team of experienced and knowledgeable travel experts who are well-versed with the local culture and can provide valuable insights and recommendations to their customers.</p>

                                <p>RS Travels is committed to providing its customers with a memorable and enjoyable travel experience, and strives to exceed their expectations with every trip. The company values customer satisfaction and is constantly working towards improving its services to ensure that its customers have a hassle-free and enjoyable travel experience.</p>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        </>
    )
};

export default About;