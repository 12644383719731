import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../logo.webp'; // Adjust the import according to your file structure
import { Link } from 'react-router-dom';

function Header() {
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={logo} alt="Logo" style={{ width: '40px', height: '40px' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto ms-5">
                        <Nav.Item>
                            <Link to="/" className="nav-link">Home</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/contact" className="nav-link">Contact</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/about" className="nav-link">About</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;

{/* <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>Home</Link>
                        <Link to="/contact" style={{ color: 'inherit', textDecoration: 'none' }}>Contact</Link>
                        <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>About</Link> */}
<Nav.Link href="/" >Home</Nav.Link>